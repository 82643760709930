import { SHIPPED_SHIELD_SKU } from '@/data/constants/SHIPPED_SHIELD_SKU'
import { WORRY_FREE_DELIVERY_SKU } from '@/data/constants/WORRY_FREE_DELIVERY_SKU'

export const checkIsShippedShieldItem = (variant: { sku: string }) => (
  `${variant.sku}`.includes(SHIPPED_SHIELD_SKU)
  || `${variant.sku}`.includes(WORRY_FREE_DELIVERY_SKU)
)

export default checkIsShippedShieldItem

export const hasShippedShieldItem = (
  variants: readonly { sku: string }[],
) => variants.some(checkIsShippedShieldItem)
